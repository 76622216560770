
import {Container, Image, Button, Row, Col, Form } from 'react-bootstrap';
import './style.scss';
import Centerheading from '../Centerheading/Centerheading';


function Signup (){
    return(
        <>
        <div className='Signup_section'>
            <Container className='section_space_bottom'>
                <a href="https://blog.synchrony.fi/contact/" target='_blank'>
                <Centerheading 
                heading="Sign Up Here for Updates + Access to Our Closed Beta!" 
                description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text." 
                type={1} 
                buttontext="Sign Up" />
                </a>
            </Container>
        </div>
        </>
    )
}
export default Signup;