
import {Container, Image, Button, Col } from 'react-bootstrap';
import './style.scss';
import Centerheading from '../../Components/Centerheading/Centerheading';

import Topbannerbg from '../../Static/topbanner-bgimage.png';
import Defi from '../../Static/Defi.svg';
import Solanalogo from '../../Static/solana-sol-logo.svg';
import Plogo from '../../Static/p-icon.svg';
import Glogo from '../../Static/G-logo.svg';
import AIlogo from '../../Static/AI-logo.svg';
import Imagelogo from '../../Static/img-icon.svg';

function Topbanner(props){  
   
    return (
        <>
          <div className='Dotbg_image'>
                <div className='bgTopbanner-Image'><img src={Topbannerbg} /></div>
                {/* <div className='Topbanner_section'>
                    <div className='topsection_images'>
                        <div className='First-Row'>
                            <div className='Circel_image'> <span> <Image src={Solanalogo} alt="Synchrony Logo" /> </span> </div>
                            <div className='Circel_image'> <span> <Image src={Plogo} alt="Synchrony Logo" /> </span> </div>
                        </div>
                        <div className='Second-Row'>
                            <div className='Circel_image'> <span> <Image src={Defi} alt="Synchrony Logo" /> </span> </div>
                            <div className='Circel_image'> <span> <Image src={Glogo} alt="Synchrony Logo" /> </span> </div>
                        </div>
                        <div className='Third-Row'>
                            <div className='Circel_image'> <span> <Image src={AIlogo} alt="Synchrony Logo" /> </span> </div>
                            <div className='Circel_image'> <span> <Image src={Imagelogo} alt="Synchrony Logo" /> </span> </div>
                        </div>
                    </div>
                </div> */}
            <Container>
                <div className='Topbanner_section'>
                     <Centerheading heading="DEFI for All" 
                    />
                    <Centerheading heading="Powered by A.I. & Analytics" 
                    firstletter="Featuring"
                    subheading="On-Chain Copy-Trading, Composable Indices, and a Friendly Defi Farmer's Market."
                    />
                    {/* <Centerheading heading="Powered by A.I. & Analytics" 
                    firstletter="Featuring"
                    subheading="On-Chain Copy-Trading, Composable Indices, and a Friendly Defi Farmer's Market."
                    /> */}
                </div>
            </Container>
            </div>
        
        </>
    );
}
export default Topbanner;