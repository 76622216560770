import {useState, useEffect} from 'react';
import SettingIcon1 from '../../Static/setting_blue.svg';
import SettingIcon2 from '../../Static/setting_skyblue.svg'
import Layout from '../../Components/Layout/Layout';
import Signup from '../../Components/Signup/Signup';
import './style.scss';

import {Container, Image, Button } from 'react-bootstrap';
import Defi from '../../Static/Defi.svg';
import Solanalogo from '../../Static/solana-sol-logo.svg';
import Plogo from '../../Static/p-icon.svg';
import Glogo from '../../Static/G-logo.svg';
import AIlogo from '../../Static/AI-logo.svg';
import Imagelogo from '../../Static/img-icon.svg';
import Topbannerbg from '../../Static/topbanner-bgimage.png';

function Comingsoon(props){  
    return (
        <>
           <Layout>
           <div className='Comingsoon_main'>
           <div className='bgTopbanner-Image'><img src={Topbannerbg} /></div>
           <div className='Topbanner_section'>
                {/* <div className='topsection_images'>
                    <div className='First-Row'>
                        <div className='Circel_image'> <span> <Image src={Solanalogo} alt="Synchrony Logo" /> </span> </div>
                        <div className='Circel_image'> <span> <Image src={Plogo} alt="Synchrony Logo" /> </span> </div>
                    </div>
                    <div className='Second-Row'>
                        <div className='Circel_image'> <span> <Image src={Defi} alt="Synchrony Logo" /> </span> </div>
                        <div className='Circel_image'> <span> <Image src={Glogo} alt="Synchrony Logo" /> </span> </div>
                    </div>
                    <div className='Third-Row'>
                        <div className='Circel_image'> <span> <Image src={AIlogo} alt="Synchrony Logo" /> </span> </div>
                        <div className='Circel_image'> <span> <Image src={Imagelogo} alt="Synchrony Logo" /> </span> </div>
                    </div>
                </div> */}
            </div>

            <Container>
                
                   
                    <h2 className='wow fadeInUp' data-wow-delay="0.3s">Coming Soon</h2>
                    <p className='wow fadeInUp' data-wow-delay="0.6s">Featuring On-Chain Copy-Trading, Composable Indices, and a Friendly Defi Farmer's Market.</p>
                    <Button className="btn btn_synchrony wow fadeInUp" data-wow-delay="0.9s">Back to Home</Button>
                
            </Container>
            </div>
            <Signup/>
           </Layout>
        </>
    );
}
export default Comingsoon;