import {Container, Image, Button } from 'react-bootstrap';
import HyperGrowth from '../../Static/HyperGrowth_Logo.png';
import Sanctorcapital from '../../Static/sanctor-capital.png';



function IconwithHeading (props) {
    return(
        <>
                <div className={props.changebgcolortrending}>
                <div className="Tradinglist-icons wow fadeInUp"  data-wow-delay={props.animationicontimer}>
                    
                    <h3><span><Image src={props.iconimage} /></span> {props.iconheading}</h3>
                    <p>{props.icondescription}</p>
                   
                </div>
                </div>
        
        </>
    )
}
export default IconwithHeading;