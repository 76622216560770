import {Image} from 'react-bootstrap';


function RecentPosts(props){
    return(
        <>
            <div className="Post_main_section wow fadeInUp" data-wow-delay={props.posttimeanimation}>
                <div className="post_category">
                    <span>{props.postcategory}</span>
                </div>
                <div className='post_Image'><Image src={props.postimage} /></div>
                <div className="post_contant">
                    <h3>{props.posttitle}</h3>
                    <p>{props.postdate}</p>
                </div>
            </div>
        </>
    )
}
export default RecentPosts;