
import {Container, Image, Button, Col } from 'react-bootstrap';
import './style.scss';
import {WOW} from 'wowjs';

function IndicesList(props){  
    const wow = new WOW({
        offset: 100,
        mobile: false,
        live: true
    })

    wow.init();
    return (
        <>
           <Col className='wow fadeInUp' data-wow-delay={props.timeranimation}>
                <div className={props.IndicesmainClass} >
                    <span><Image src={props.Indiceslisticon} /></span>
                    <h2> {props.Indiceslistheading} </h2>
                    <p> {props.Indiceslistdescription}</p>
                    
                </div>
            </Col>
        
        
        </>
    );
}
export default IndicesList;