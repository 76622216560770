import {useState, useEffect} from 'react';
import {Container, Image, Button, Row, Col } from 'react-bootstrap';
import './style.scss';
import Header from '../../Components/Header/Header';
import Footer from '../../Components/Footer/Footer';
import Topbanner from './Topbanner';

import Centerheading from '../../Components/Centerheading/Centerheading';
import Logoslider from './Logoslider';
import IconwithHeading from './IconwithHeading';
import IndicesList from './IndicesList';
import RecentPosts from './RecentPosts';
import LeftcontantDetails from './LeftcontantDetails';

import Postimage from '../../Static/FoundersIMGalt-p-800.png';
import Featuredimage from '../../Static/featured-image.png';

import Plogo from '../../Static/p-icon.svg';
import Glogo from '../../Static/G-logo.svg';
import Imagelogo from '../../Static/img-icon.svg';
import coingecko from '../../Static/coingecko.png';
import coinmarketcap from '../../Static/coinmarketcap_1.svg';
import Copytrendingmain from '../../Static/Copytrending-Mainimage-2.png';
import Copytradinganimation from '../../Static/copy-trading-animation.svg';



import Fasticon from '../../Static/Copy_fast.svg';
import Simpleicon from '../../Static/Copy_simple.svg';
import Trulyicon from '../../Static/Copy_Truly.svg';

import IndicesView from '../../Static/Indices_View.svg';
import IndicesAddchart from '../../Static/Indices_Addchart.svg';
import IndicesSearch from '../../Static/Indices_Search.svg';

import builtOnSolana from '../../Static/builtOnSolana.svg';
import AnalyticsImage from '../../Static/AnalyticsImage.png';
import AndrewFraser from '../../Static/Team_AndrewFraser.png';
import AndyKeh from '../../Static/Team_AndyKeh.png';
import MohammedElAmine from '../../Static/TeamMohammedElAmine.png';
import MauriceChalfin from '../../Static/Team_MauriceChalfin.png';
import SouleimaneChbani from '../../Static/SouleimaneChbani.png';

import FarmerMarketImage from '../../Static/FarmerMarketImage.png';

import Particles from '../../Components/Particles/Particles';
import { tsParticles } from "tsparticles-engine";

import Rotateimage from '../../Components/Rotateimage/Rotateimage';
import Teamsynchrony from './Teamsynchrony';
import Signup from '../../Components/Signup/Signup';

import {WOW} from 'wowjs';

function HomePage (){  
	const wow = new WOW({
        offset: 100,
        mobile: false,
        live: true
    })
    wow.init();


	const [scroll, setScroll] = useState(false);
	useEffect(() => {
	window.addEventListener("scroll", () => {
		var elem = document.getElementById('animationSection');
		var elemRect = elem && elem.getBoundingClientRect();
		var elemViewportOffset = elemRect && elemRect.top;
		console.log("elemViewportOffset",elemViewportOffset)
		if(elemViewportOffset && elemViewportOffset < 150 && scroll === false){
			setScroll(true);
		}
		
	});
	}, []);




	


	return (
      <>
	  
	<Header />	  
	<Topbanner />	 
	{/* <Particles/> */}



	<div className='main_section main-imagessection'>
		<Container>
			<div className='SynchronyDashboard_image wow fadeInUp'>
				<Image src={Featuredimage} alt="Synchrony Dashboard" data-wow-delay="0.3s"/>
			</div>

			

			<div className='Logoicon_Topbanner'>
				<div className='Circel_image wow fadeInUp' data-wow-delay="0.3s"> <span> <Image src={Plogo} alt="Synchrony Logo" /> </span> </div>
				<div className='Circel_image wow fadeInUp' data-wow-delay="0.6s"> <span> <Image src={Glogo} alt="Synchrony Logo" /> </span> </div>
				<div className='Circel_image wow fadeInUp' data-wow-delay="0.9s"> <span> <Image src={Imagelogo} alt="Synchrony Logo" /> </span> </div>
			</div>

			{/* <Centerheading 
			firstletter="Featuring" 
			subheading=" On-Chain Copy-Trading, Composable Indices, and a Friendly Defi Farmer's Market." 
			/> */}

			<div className='Topsection_btns text-center  wow fadeInUp' data-wow-delay="0.5s">
				<a href='#' className='btn btn-bgfill'>TRADE on Raydium</a>
				<a href='#' className='btn btn-bgborder'>TRADE on Ascendex</a>
			</div>

			<div className='featured_main text-center'>
				<h2 className=' wow fadeInUp' data-wow-delay="0.3s">
					Featured on 
					<span ><a href="https://coinmarketcap.com/fr/currencies/synchrony/" target="_blank"><Image src={coinmarketcap} alt="Synchrony Logo" /></a> </span>
					<span className='coingecko_logo'><a href="https://www.coingecko.com/en/coins/synchrony" target="_blank"><Image src={coingecko} alt="Synchrony Logo" /></a> </span> 
				</h2>
			</div>
		</Container>
	</div>

	
	<Logoslider />

	<div className='bg_threesection'>
	<div className='whatSynchrony_main'>
	<Container>
		<Row className="justify-content-md-center">
			<Col md={8}>
				<div className='WhatSynchrony_contant'>
					<h2 className='wow fadeInUp'  data-wow-delay="0.3s">What is Synchrony?</h2>
					<span className='wow fadeInUp'  data-wow-delay="0.5s"><Image src={builtOnSolana} alt="Solana" /> </span>
					<p className='wow fadeInUp'  data-wow-delay="0.7s">Synchrony is truly decentralized asset management powered by A.I. & Analytics; the first on-chain copy-trading and composable indexing protocol, facilitated by a friendly DeFi Farmer’s Market. Copy what other traders do, invest passively with our dynamic indices (or create your own!), and do-your-own-research (DYOR) using our holistic suite of analytics tools.</p>
					<Button className='btn btn_border_sync wow fadeInUp'  data-wow-delay="0.9s">Learn More</Button>
				</div>
			</Col>
		</Row>
		
	</Container>
	</div>
	<div className='TeamSynchrony_main'>
	<Container>
		<Row>
			<Teamsynchrony teamimage={AndrewFraser}  animationtimerteam="0.2s"
			teamname="Andrew Fraser" 
			teamdescription="Architect/Engineer" />

			<Teamsynchrony teamimage={AndyKeh}  animationtimerteam="0.4s"
			teamname="Andy Keh" 
			teamdescription="Engineer" />

			<Teamsynchrony teamimage={MohammedElAmine} animationtimerteam="0.6s"
			teamname="Mohammed El Amine" 
			teamdescription="Data Scientist & Software Engineer" />

			<Teamsynchrony teamimage={SouleimaneChbani} animationtimerteam="0.8s"
			teamname="Souleimane Chbani" 
			teamdescription="Marketing, Communications & Community" />
		</Row>
	</Container>
	</div>
	</div>



	<div className='CopyTrading_section_main' id="animationSection">
		<Container className='CopyTrading_section'>
			<Row>
				<Col md={12} >
					
					<Centerheading 
					heading="Copy-Trading" 
					description="You don’t have a trading experience? Now you can copy our professional traders with a single click and automate all of your trades in less than 3 minutes, start with us." 
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<div className='Copytrendingmain-image wow fadeInUp' data-wow-delay="0.3s">
						<span  className={scroll ? "Show_copy_image" : "Hide_copy_image"}>
							<object type="image/svg+xml" data={Copytradinganimation}>svg-animation</object>
						</span>

						{/* <div className='SettingIcon'>
							<Rotateimage />
						</div> */}

					</div>
					
				</Col>
				
				
			</Row>
			<Row>
				<Col md={4}>
					<IconwithHeading iconimage={Fasticon} animationicontimer="0.3s"
					iconheading="Fast" 
					icondescription="Utilizing Solana's high throughput and transaction speed, copy-trading on Synchrony is executed with very little risk of price divergence or front-running" />
				</Col>
				<Col md={4}>
					<IconwithHeading iconimage={Simpleicon} animationicontimer="0.6s" 
					iconheading="Simple" 
					icondescription="input any wallet address or select a leader-wallet from the Synchrony leaderboard and Synchrony does the rest" />
				</Col>
				<Col md={4}>
					<IconwithHeading iconimage={Trulyicon} animationicontimer="0.9s"
					iconheading="Truly Trustless" 
					icondescription="Truly trustless with no limitations wallet behaviour can be governed by user-configured parameters. Synchrony optimizes wallet behaviour to ensure that copy-trade strategies match a user's risk profile					" />
				</Col>
			</Row>
		</Container>
	</div>
	
	<div className='main_section Indices_image_section'>
	<Container className='Indices_section'>
		
		<Centerheading 
		heading="Indices" 
		description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book." 
		/>
		
		<Row>
			
			<IndicesList IndicesmainClass="Indiceslis_main orangebox" Indiceslisticon={IndicesView} 
			Indiceslistheading="Composable, Modular, and Dynamic" 
			Indiceslistdescription="Evaluate any set of on-chain instruments from tokens, to liquidity pools, to yield farms, and even NFTs." 
			timeranimation="0.2s" />

			<IndicesList IndicesmainClass="Indiceslis_main bluebox" Indiceslisticon={IndicesAddchart} 
			Indiceslistheading="Programmatically managed" 
			Indiceslistdescription="Automatically rebalanced, and algorithmically optimized - single-click solutions for executing complex strategies with as little friction as possible" 
			timeranimation="0.4s" />

			<IndicesList IndicesmainClass="Indiceslis_main purpalbox" Indiceslisticon={IndicesSearch} 
			Indiceslistheading="Transparent" 
			Indiceslistdescription="Indices are analogous to smart contracts - an index's calculation methodology can be scrutinized in the same way a smart contract's code can." 
			timeranimation="0.6s" />
		</Row>
	</Container>
	</div>
	<div className='main_section main_Analytics_section'>
	<Container className='section_space'>
	<Row className='align_item_center Analytics_section'>
			<Col md={6} className='mobile_order2'>
				<div className='leftside_image wow fadeInUp'  data-wow-delay="0.3s">
					<span><Image src={AnalyticsImage} alt="Synchrony Logo" /> </span>
				</div>
			</Col>
			<Col md={6} className='mobile_order1'>

				<LeftcontantDetails Leftcontantheading="Analytics" 
				firstletter="Powerful"
				Leftcontantdescription="Synchrony's best-in-class on-chain analytics provides users with both macro-level insights into ecosystem sentiment and granular detail on any user's on-chain behaviour" 
				firstletter2="Efficient"
				Leftcontantdescription2="Synchrony help's users paint a picture of the on-chain landscape in a streamlined and intuitive user-interface" 
				firstletter3="Holistic"
				Leftcontantdescription3="Synchrony's analytics engine is powered by a combination of off-chain and on-chain data" 
				 />

			</Col>
		</Row>
	</Container>
	</div>
	<div className='main_section Farmer_main_section'>
	<Container className='section_space'>
		<Row className='align_item_center'>
			<Col md={6}>

			<LeftcontantDetails Leftcontantheading="Farmer's Market" 
				firstletter="Aggregate"
				Leftcontantdescription=" Access all of Solana’s dApps from one place." 
				firstletter2="Optimize"
				Leftcontantdescription2=" Single-click solutions that remove unnecessary steps from on-chain interactions" 
				firstletter3="Friendly"
				Leftcontantdescription3=" Synchrony's farmer's market puts a smile on your face." 
				 />
				
			</Col>

			<Col md={6}>
				<div className='rightside_image wow fadeInUp' data-wow-delay="0.3s">
					<span><Image src={FarmerMarketImage} alt="Synchrony Logo" /> </span>
				</div>
			</Col>
		</Row>
	</Container>

	</div>
	

	<div className='main_section recentpost_mainsection'>
		<Container className='section_space'>
			<Row>
				<Col>
					<div className='Heading_leftside wow fadeInUp' data-wow-delay="0.3s">
						<h3>Recent Posts</h3>
					</div>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<div className='list_postDetails_left'>
						<RecentPosts posttimeanimation="0.3s" postcategory="DEFI" 
						postimage={Postimage} 
						posttitle="Synchrony's Founding Team" 
						postdate="04 September 2021" />
					</div>
				</Col>
				<Col md={6}>
					<div>
						<ul className='list_postDetails_right'>
							<li>
								<RecentPosts  posttimeanimation="0.5s" postcategory="DEFI" 
								postimage={Postimage} 
								posttitle="Synchrony's Founding Team" 
								postdate="04 September 2021" />
							</li>
							<li>
								<RecentPosts  posttimeanimation="0.6s" postcategory="DEFI" 
								postimage={Postimage} 
								posttitle="Synchrony's Founding Team" 
								postdate="04 September 2021" />
							</li>
							<li>
								<RecentPosts  posttimeanimation="0.7s" postcategory="DEFI" 
								postimage={Postimage} 
								posttitle="Synchrony's Founding Team" 
								postdate="04 September 2021" />
							</li>
							<li>
								<RecentPosts  posttimeanimation="0.8s" postcategory="DEFI" 
								postimage={Postimage} 
								posttitle="Synchrony's Founding Team" 
								postdate="04 September 2021" />
							</li>
						</ul>
					</div>
				</Col>
			</Row>
		</Container>
	</div>


	<Signup />
	<Footer />

	  </>
	 )
}
export default HomePage;