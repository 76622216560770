import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import logo from '../../Static/Synchrony.svg';
import './style.scss';
import Footerlogo from '../../Static/Footerlogo.svg';
import { FaFacebookF, FaTwitter, FaTelegramPlane, FaLinkedinIn } from 'react-icons/fa';

function Footer (){  
    return (
        <>
           <div className="footer_mian" id="Footer">
                <Container>
                    <Row>
                        <Col>
                            <div className='Synchronyfooter_logo'>
                                <Image src={Footerlogo} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <div className='Social_footer'>
                            <ul>
                                <li><a href="#"><FaFacebookF /> </a></li>
                                <li><a href="#"> <FaTwitter /> </a></li>
                                <li><a href="#"><FaTelegramPlane /></a></li>
                                <li><a href="#"> <FaLinkedinIn /> </a></li>
                            </ul>
                        </div>
                    </Row>
                    <Row>
                        <Col>
                            <div className='footer_links'>
                                <ul>
                                    <li><a href="#">Products</a></li>
                                    <li><a href="#">Developer</a></li>
                                    <li><a href="#">Community</a></li>
                                    <li><a href="#">Blogs</a></li>
                                    <li><a href="#">Contact us</a></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p className='Copyright_section'>©2022 Synchrony Labs. All Rights Reserved.</p>
                        </Col>
                    </Row>
                </Container>    
            </div>
        </>
    );
}
export default Footer;