import {Container, Image, Button } from 'react-bootstrap';
import Owlslider from '../../Components/Slider/owlslider';
import Centerheading from '../../Components/Centerheading/Centerheading';


function Logoslider () {
    return(
        <>
        <div className="Logosynchrony_main">
        	<Container>
            {/* <Centerheading heading="Backed by" /> */}

                <Owlslider />

                
            </Container>
        </div>
        </>
    )
}
export default Logoslider;