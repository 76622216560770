import {Container, Image, Button, Row, Col, Form } from 'react-bootstrap';
import './style.scss';
import {useState} from 'react';


function Contactform (){

    const[username, setUsername] = useState("");
    const[email, setEmail] = useState("");
    const[errorshow, setErrorshow] = useState(false);
    const[thankyou, setThankyou] = useState(false);

    const handleSubmit = (e) => {
        if(username === "" || email === "") {
            setErrorshow(true);
            
        }else {
            alert(123);
            
        }
    }

    return(
        <>
            <div>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First Name" onChange={(e) => setUsername(e.target.value)} />
                        {errorshow && <p className="formerror">Please Enter First name</p>}
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last Name" />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="text" placeholder="Email Address" onChange={(e) => setEmail(e.target.value)} />
                        {errorshow && <p className="formerror">Please Enter Email Address</p>}
                    </Form.Group>

                    <Form.Group className="mb-3" >
                        <Form.Label>Your Message</Form.Label>
                        <Form.Control as="textarea" rows={3} />
                    </Form.Group>

                    <Button variant="primary"  onClick={handleSubmit}>
                        Submit
                    </Button>
                    {thankyou && <p>Thank you for your message, We will connect you soon</p>}
                </Form> 
            </div>
        </>
    )
}
export default Contactform;