import {useState, useEffect} from 'react';
import SettingIcon1 from '../../Static/setting_blue.svg';
import SettingIcon2 from '../../Static/setting_skyblue.svg'
import {Container, Image, Button, Row, Col } from 'react-bootstrap';


function Teamsynchrony(props){  

    return (
        <>
           <Col md={3}>
				<div className='Synchrony_team wow fadeInUp'  data-wow-delay={props.animationtimerteam}>
					<div className='teamuser_image'><Image src={props.teamimage} /> </div>
					<div className='Teamhover_contant'>
						<h4>{props.teamname}</h4>	
						<p>{props.teamdescription} </p>	
					</div>		
				</div>
			</Col>
           
        </>
    );
}
export default Teamsynchrony;