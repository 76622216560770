import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './style.scss';

import Solanalogo from '../../Static/solana-sol-logo.svg';
import HyperGrowth from '../../Static/HyperGrowth_Logo.png';
import Xlogo from '../../Static/x21.png';
import Wintermute from '../../Static/wintermute-logo.png';
import Sanctorcapital from '../../Static/sanctor-capital.png';
import Panonylogo from '../../Static/panony-logo.png';
import ellipti from '../../Static/ellipti.png';
import mgnr from '../../Static/mgnr.png';
import arklogo from '../../Static/arklogo.png';

function Owlslider(){
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 9
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 9
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 2
        }
      };
    return(
    <>
     
       <Carousel  swipeable={false}
        draggable={true}
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px" >
            <div className='lofoslider_images'><img src={Solanalogo} /></div>
            <div className='lofoslider_images'><img src={HyperGrowth} /></div>
            <div className='lofoslider_images'><img src={Xlogo} /></div>
            <div className='lofoslider_images'><img src={Wintermute} /></div>
            <div className='lofoslider_images'><img src={Sanctorcapital} /></div>
            <div className='lofoslider_images'><img src={Panonylogo} /></div>
            <div className='lofoslider_images'><img src={ellipti} /></div>
            <div className='lofoslider_images'><img src={mgnr} /></div>
            <div className='lofoslider_images'><img src={arklogo} /></div>
            <div className='lofoslider_images'><img src={Sanctorcapital} /></div>
       </Carousel>
      
    </>
    );
}
export default Owlslider;