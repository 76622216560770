import {Container, Nav, Navbar, Image, Button } from 'react-bootstrap';
import logo from '../../Static/Synchrony.svg';
import './style.scss';

import { FaArrowRight } from 'react-icons/fa';

function Header (){  

    const handleToggle = () => {
        document.body.classList.toggle("fixmenu");
     }

    return (
        <>
           <Navbar  expand="lg" className="headernavbar">
                <Container>
                    <Navbar.Brand href="#">
                        <Image src={logo} alt="Synchrony Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={handleToggle}/>
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto navbarcenter">
                        <Nav.Link href="#">Products</Nav.Link>
                        <Nav.Link href="#">Developer</Nav.Link>
                        <Nav.Link href="#">Community</Nav.Link>
                        <Nav.Link href="#">Blog</Nav.Link>
                        <Nav.Link href="#">Contact us</Nav.Link>
                    </Nav>
                    <div className='header_button'>
                        <Button className="btn btn_synchrony" >Launch App  </Button>
                    </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}
export default Header;