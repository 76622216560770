import {Container, Image, Button } from 'react-bootstrap';
import './style.scss';



function Centerheading(props){  
    return (
        <>
           
        <div className='CenterHeading'>
            <h2 className='wow fadeInUp' data-wow-delay="0.3s"> {props.heading} </h2>
            <h4 className='wow fadeInUp' data-wow-delay="0.5s"> <span className='subheading_firstletter'>{props.firstletter} </span>  {props.subheading} </h4>
            <p className='wow fadeInUp' data-wow-delay="0.7s"> {props.description}</p>
            {props.type === 1 && 
                <Button className="btn btn_synchrony wow fadeInUp" data-wow-delay="0.9s">{props.buttontext}</Button>
            }
        </div>
        
        </>
    );
}
export default Centerheading;