import Header from './../Header/Header';
import Footer from './../Footer/Footer';


function Layout (props){
    const {children} = props;
    return(
        <>
            <Header />
                {children}
            <Footer />
        </>
    )
}
export default Layout;