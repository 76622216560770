import {Container, Image, Button } from 'react-bootstrap';
import './style.scss';

function LeftcontantDetails(props){  
    return (
        <>
           
        <div className='leftcontantDetails' >
            <h2 className=' wow fadeInUp'  data-wow-delay="0.3s"> {props.Leftcontantheading} </h2>
            <p className=' wow fadeInUp'  data-wow-delay="0.3s"> <span className="firstletter">{props.firstletter}</span> {props.Leftcontantdescription}</p>
            <p className=' wow fadeInUp'  data-wow-delay="0.6s"> <span className="firstletter">{props.firstletter2}</span> {props.Leftcontantdescription2}</p>
            <p className=' wow fadeInUp'  data-wow-delay="0.7s"> <span className="firstletter">{props.firstletter3}</span> {props.Leftcontantdescription3}</p>
            
        </div>
        
        </>
    );
}
export default LeftcontantDetails;