import Layout from '../../Components/Layout/Layout';
import {Container, Image, Button, Row, Col, Form } from 'react-bootstrap';
import Contactform from './Contactform';
import './style.scss';
import Centerheading from '../../Components/Centerheading/Centerheading';

import { FaFacebookF, FaTwitter, FaTelegramPlane, FaLinkedinIn, FaPhoneAlt, FaRegEnvelope } from 'react-icons/fa';


function Contact (){
    return(
        <>
        <Layout>
            <div className='Contactus_Main'>
            <Container>
                <Row>
                    <Col>
                        <Centerheading 
                            heading="Contact us" 
                            description="Please give us a bit to reply 🙂" 
                        />
                        
                    </Col>
                </Row>
                <Row className='Contactform'>
                    <Col>
                        <div className="image_contatcuspage">
                            <h3>Let's Talk</h3>
                            <p>Loream ipsum is the dummt text. it is very healpfull to use.</p>
                        </div>
                        <div className='dflex contact_info'>
                            <p><span><FaPhoneAlt/> </span> 123 456 7890 </p>
                            <p><span><FaRegEnvelope/> </span> info@synchrony.fi </p>
                        </div>
                        <div className='Contact_social'>
                            <h3>Social Links</h3>
                            <ul>
                                <li><a href="#"><FaFacebookF /> </a></li>
                                <li><a href="#"> <FaTwitter /> </a></li>
                                <li><a href="#"><FaTelegramPlane /></a></li>
                                <li><a href="#"> <FaLinkedinIn /> </a></li>
                            </ul>
                        </div>
                    </Col>
                    <Col>
                         <Contactform />
                    </Col>
                </Row>
            </Container>
            
            </div>
        </Layout>
        </>
    )
}
export default Contact;